import React from "react";
import loadable from "@loadable/component";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import {Helmet } from "react-helmet"
import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import BannerRender from "@components/modules/Banner/BannerRender";
import OfficesCards from "@components/officesCards";
//import GoogleMultipleMap from "@components/maps/google/multiple";
import OfficeDetails from "../templates/office-details";
import Blue_Trans from "../images/Blue_Trans.jpg";
const GoogleMultipleMap = loadable(() => import("@components/maps/google/multiple"));

const ContactLanding = ({
    data, location
}) => {
    let myofficeslug = ''
  let pathUri_main = location.pathname.split("/contact-exclusive-links/");

  if ( pathUri_main[1] ) {
    let pathUri_arr = pathUri_main[1].split("?")[0].split("/")
    myofficeslug = pathUri_arr[0];
  }

  if (myofficeslug) {
      return(
        <OfficeDetails myslug={myofficeslug}  />
      )
      
  }
  else {
    const { glstrapi: { pages, offices } } = data
    var page = pages[0]
    // const pageurl = typeof window !== 'undefined' ? window.location.pathname : page?.Slug
    const breadcrumbs = [
        { label: page?.Pagename, url: `${page?.Slug}` }
    ]
    
    return (
        
        <div className="contact-landing-page">
        <Layout popularSearch={"Contact_Layout"}>
            <Helmet>
            <script type="application/ld+json">{`{
         
         "@context": "http://schema.org",
         "@type": "Product",
       "name" : "${page?.Metadata?.title}",
       "description" : "${page?.Metadata?.description}",
      "brand" : {
         "@type" : "Brand",
         "name" : "Exclusive Links",
         "image" : "${Blue_Trans}" 
       },
     "aggregateRating": {
             "@type": "AggregateRating",
            "ratingValue": "4.75",
             "reviewCount": "427",
             "bestRating": "5"
     }
     }`}
         </script>
         
            </Helmet>
            <Seo
                title={page.Metadata?.title ? page.Metadata.title : page?.Title}
                description={page.Metadata?.description ? page.Metadata.description : null}
                image={page.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
                className="more-bottom-padding"
            />
            <OfficesCards
                className="pt-0 pb-40 pt-md-0 pb-md-64 pt-lg-0 pb-lg-80 mt-n70 mt-md-n120 section-transparent position-relative z-index-5"
            />
            <GoogleMultipleMap
                markers={offices}
                height="500px"
                lat={25.054387006289776}
                lng={55.23080857595263}
                zoom={9}
                className="office-landing-map"
                isofficePage={true}
            />
            {page.BannerImage &&
                <ModuleRender
                    moduleData={page.BannerImage}
                />
            }
        </Layout>
        </div>
    )
        }
}

export default ContactLanding

export const query = graphql`
query {
    glstrapi {
        pages(publicationState: LIVE, where: {PageTemplate: "ContactUs"}, limit: 1) {
            Pagename
            Title
            Slug
            HeaderTemplate
            PageTemplate
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
        offices(publicationState: LIVE) {
            id
            Title
            Slug
            MapLong
            MapLat
        }
    }
}
`