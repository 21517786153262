import React, {useEffect} from "react";
import loadable from "@loadable/component";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
// import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleText from '@components/modules/Text';
import BannerRender from "@components/modules/Banner/BannerRender";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import GoogleSingleMap from "@components/maps/google/single";
//import GoogleSingleMap from "@components/maps/google/place-and-direction";
import ContactUsModule from '@components/modules/Forms/ContactUs';
import { Routes } from '@components/routes';
import { fadeInFromBottom } from '@components/utils/animations';
import { cleanP } from '@components/utils/CleanedHtml';
import {
    FloatBannerFragmentGql,
    PropertySearchFragmentGql,
    IconBoxFragmentGql,
    PropertySliderFragmentGql,
    BoxCtaFragmentGql,
    AboutPeopleFragmentGql,
    AwardsSliderFragmentGql,
    GoogleCtaFragmentGql,
    LatestPodcastsFragmentGql,
    LatestVideosFragmentGql
} from "@components/gql";
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Helmet } from "react-helmet";
import NotFoundPage from "../pages/404"
import Loader from '@components/loader';
import Blue_Trans from "../images/Blue_Trans.jpg";

const GoogleSingleMap = loadable(() => import("@components/maps/google/place-and-direction"));

const OfficeDetails = (props) => {
    const GET_DETAIL_OFFICE = gql`
    query GetOfficeDetail($Slug: String!) {
        offices(where:{Slug: $Slug}){
            Pagename
            AboutOffice
            Address
            Enquire
            MapLat
            MapLong
            OpeningHour
            PhoneNumber
            Slug
            Schema
            Title
            WhatsappNumber
            id
            HeaderTemplate
            imagetransforms
            BannerImage {
                ... on ComponentModulesBannerImage {
                    ...FloatBannerFragmentGql
                }
                ... on ComponentModulesSearchCta {
                    ...PropertySearchFragmentGql
                }
                ... on ComponentModulesCustomerReviewModule {
                    id
                    moduleType
                    ReviewSectionTitle
                    ReviewSectionDesc
                  }
                ... on ComponentModulesIconBox {
                    ...IconBoxFragmentGql
                }
                ... on ComponentModulesPropertySlider {
                    ...PropertySliderFragmentGql
                }
                ... on ComponentModulesBoxCta {
                    ...BoxCtaFragmentGql
                }
                ... on ComponentModulesAboutPeople {
                    ...AboutPeopleFragmentGql
                }
                ... on ComponentModulesAwards {
                    ...AwardsSliderFragmentGql
                }
                ... on ComponentModulesGoogleCta {
                    ...GoogleCtaFragmentGql
                }
                ... on ComponentModulesLatestPodcasts {
                    ...LatestPodcastsFragmentGql
                }
                ... on ComponentModulesLatestVideos {
                    ...LatestVideosFragmentGql
                }
               
            }
            Metadata {
                title
                description
                image {
                    url
                }
            }
  
        }
    }
    ${FloatBannerFragmentGql}
    ${PropertySearchFragmentGql}
    ${IconBoxFragmentGql}
    ${PropertySliderFragmentGql}
    ${BoxCtaFragmentGql}
    ${AboutPeopleFragmentGql}
    ${AwardsSliderFragmentGql}
    ${GoogleCtaFragmentGql}
    ${LatestPodcastsFragmentGql}
    ${LatestVideosFragmentGql}
`;
    const { loading, error, data } = useQuery(GET_DETAIL_OFFICE, {
        variables: { Slug: props.myslug }
    });
    useEffect(() => {
        if(typeof window !== 'undefined'){
            window.scrollTo(0,0)
            // window.addEventListener('contextmenu', function (e) {
            //     e.preventDefault();
            //   });
        }
    }, [data])
    // const { glstrapi: { office } } = data


    if (loading) return (
        <Layout loading={loading}>
        <Loader loading={loading} error={error} hideText={true} fullScreen />
        </Layout>
    );
    if (data?.offices?.length === 0) return (
        <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
    )
   
    var officedata = data?.offices[0]
    var ratingValue = ""
    var reviewCount = ""
    var bestRating = ""
    if(officedata){
        if(officedata?.Pagename === "Head Office"){
            ratingValue = 4.75
            reviewCount = 427 
            bestRating = 5
        }else if(officedata?.Pagename === "Dubai Marina Office"){
            ratingValue = 4.7
            reviewCount = 93
            bestRating = 5
        }else{
            ratingValue = 4.75
            reviewCount = 427
            bestRating = 5
        }
    }
       
        
    //console.log("dataoffice",officedata)
    return (
        <Layout popularSearch={"Contact_Layout"}>
            <Helmet>
                <script type="application/ld+json">{
                JSON.stringify(data?.offices[0]?.Schema , null ,2)
                }   
                </script>
                <script type="application/ld+json">{`{
         
                "@context": "http://schema.org",
                "@type": "Product",
                "name" : "${officedata?.Metadata?.title}",
                "description" : "${officedata?.Metadata?.description}",
                "brand" : {
                    "@type" : "Brand",
                    "name" : "Exclusive Links",
                    "image" : "${Blue_Trans}" 
                },
                "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "${ratingValue}",
                        "reviewCount": "${reviewCount}",
                        "bestRating": "${bestRating}"
                }
     }`}
         </script>
            </Helmet>
            {data?.offices?.map((Page, i) => {
                const pageurl = typeof window !== 'undefined' ? window.location.pathname : Page?.Slug
                const breadcrumbs = [
                    { label: "Contact Us", url: `${Routes.ContactUsPage}` },
                    { label: Page?.Pagename, url: `${pageurl}` }
                ]
                return (
                    <>
                        <Seo
                            // title={"Properties Consultants in "+Page?.Pagename}
                            // description={"Our real estate brokers in "+Page?.Pagename+" offer the best properties advice in selling, buying, letting and renting a residential or commercial properties. Contact us today to get assistance."}
                            title={Page?.Metadata?.title}
                            description={Page?.Metadata?.description}
                            image={Page?.Metadata?.image?.url ? Page.Metadata.image.url : null}
                        />
                        <BannerRender
                            page={Page}
                            breadcrumbs={breadcrumbs}
                            office={Page}
                        />
                        <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                            <Row>
                                <Col xs={12} lg={6}>
                                    {Page?.OpeningHour.length > 0 &&
                                        <div className="office-opening_details" variants={fadeInFromBottom}>
                                            <h3 className="mb-16 office-opening_details__title">Opening Hours:</h3>
                                            <ModuleText
                                                text={Page.OpeningHour}
                                                className=""
                                            />
                                        </div>
                                    }
                                    {Page?.AboutOffice.length > 0 &&
                                        <div className="mt-32 mt-md-48 office-opening_details" variants={fadeInFromBottom}>
                                            {Page.Pagename === "Dubai Marina Office" ? (
                                                <h3 className="mb-16 office-opening_details__title">About real estate agency in Dubai Marina:</h3>
                                            ) : Page.Pagename === "Abu Dhabi Office" ? (
                                                <h3 className="mb-16 office-opening_details__title">About real estate agency in Abu Dhabi:</h3>
                                            ) : (
                                                <h3 className="mb-16 office-opening_details__title">About this Office:</h3>
                                            )}
                                        
                                            <ModuleText
                                                text={Page.AboutOffice}
                                                className=""
                                            />
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </ModuleSection>
                        <ContactUsModule
                            sectionClass="section-grey section-contact-form"
                            title={Page.Pagename === "Dubai Marina Office" ? "Contact our local estate agents in Dubai Marina" : "Contact Office"}
                            text="<p>We look forward to assisting you with any real estate requirements and please fill in your details. A member of the team will be in contact with you shortly.</p>"
                        />
                        <div className="property-details-map-wrap">
                        <GoogleSingleMap
                            title={cleanP(Page?.Title)}
                            lat={Page?.MapLat}
                            lng={Page?.MapLong}
                            destination={cleanP(Page?.Address?.replace("<br />", " "))}
                            className="office-details-map"
                        />
                        </div>
                        {Page?.BannerImage &&
                            <ModuleRender
                                moduleData={Page?.BannerImage}
                            />
                        }
                    </>
                )
            })}
        </Layout>
    );
}

export default OfficeDetails

// export const query = graphql`
// query OfficeDetailsQuery($id: ID!) {
//     glstrapi {
//         office(id: $id, publicationState: LIVE) {
//             Pagename
//             AboutOffice
//             Address
//             Enquire
//             MapLat
//             MapLong
//             OpeningHour
//             PhoneNumber
//             Slug
//             Title
//             WhatsappNumber
//             id
//             HeaderTemplate
//             imagetransforms
//             BannerImage {
//                 ... on GLSTRAPI_ComponentModulesBannerImage {
//                     ...FloatBannerFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesSearchCta {
//                     ...PropertySearchFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesIconBox {
//                     ...IconBoxFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesPropertySlider {
//                     ...PropertySliderFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesBoxCta {
//                     ...BoxCtaFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesAboutPeople {
//                     ...AboutPeopleFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesAwards {
//                     ...AwardsSliderFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesGoogleCta {
//                     ...GoogleCtaFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesLatestPodcasts {
//                     ...LatestPodcastsFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesLatestVideos {
//                     ...LatestVideosFragment
//                 }
//             }
//             Metadata {
//                 title
//                 description
//                 image {
//                     url
//                     url_sharp {
//                         childImageSharp {
//                             resize(width: 1200) {
//                                 src
//                                 height
//                                 width
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `